import {
  Box,
  Card,
  Text,
  Page,
  WixDesignSystemProvider,
  Button,
  Layout,
  Cell,
  Badge,
  Heading,
  EmptyState,
  TextButton,
  MarketingPageLayout,
  MarketingLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import * as BC from "@certifiedcode/base-components";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  // const token = new URLSearchParams(window.location.search).get("token");
  // const instance = new URLSearchParams(window.location.search).get("instance");
  // const [isUpgraded, setIsUpgraded] = React.useState(false);
  // const [usageNumber, setUsageNumber] = React.useState(0);

  // const [instanceData, setInstanceData] = React.useState({
  //   instance: {
  //     isFree: true,
  //     availablePlans: [],
  //     instanceId: "",
  //   },
  // });

  // useEffect(() => {
  //   setIntercomIdentity();
  //   setInstance();
  // }, []);

  // const BASE_URL = `https://certifiedcode.wixsite.com/custom-dashboard/_functions`;

  // function setIntercomIdentity() {
  //   fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
  //     method: "GET",
  //     headers: {
  //       Authorization: instance || "",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.hash && data.email) {
  //         window.Intercom("boot", {
  //           email: data.email,
  //           user_hash: data.hash,
  //         });
  //       }
  //     })
  //     .catch(() => {});
  // }

  // function setInstance() {
  //   fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
  //     method: "GET",
  //     headers: {
  //       Authorization: instance || "",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       setInstanceData(data);
  //       setIsUpgraded(data.instance.isFree === false);
  //     })
  //     .catch(() => {});
  // }

  // if (token) {
  //   window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=d94fde45-38b9-4041-9094-efcbb577f318&redirectUrl=https://certifiedcode.wixsite.com/custom-dashboard/_functions/@certifiedcode/base-backend/auth`;
  //   return (
  //     <WixDesignSystemProvider features={{ newColorsBranding: true }}>
  //       <Page height="100vh">
  //         <Page.Content>
  //           <Box height={"90vh"} direction="vertical" verticalAlign="middle">
  //             <Loader text="Loading" />
  //           </Box>
  //         </Page.Content>
  //       </Page>
  //     </WixDesignSystemProvider>
  //   );
  // }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={"Template Support Center"}
          subtitle={
            "Get help from our team of experts. We're here to help you with any questions you may have."
          }
        />
        <Page.Content>
          <Layout>
          <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="medium"
                  title="Access to our resources library to help you get started"
                  description="Replace images, text, and more with our step-by-step guides. Launch your site in no time."
                  actions={
                    <Button
                      prefixIcon={<Icons.AIFilledSmall />}
                      skin="ai"
                      href="https://support.certifiedcode.us/en/collections/6579544-templates"
                      target="_blank"
                      size="small"
                      as="a"
                    >
                      Go to Help Center
                    </Button>
                  }
                />
              </Card>
            </Cell>
            <Cell span={6}>
              <Card>
                <MarketingLayout
                  size="medium"
                  title="Start a live chat"
                  description="Our support team can assist with any questions you may have. Available within 90 days of purchase."
                  actions={
                    <Button
                      prefixIcon={<Icons.ChatSmall />}
                      id="site-template-support"
                      size="small"
                    >
                      Start Chat
                    </Button>
                  }
                />
              </Card>
            </Cell>
            <Cell span={6}>
              <Card>
                <MarketingLayout
                  size="medium"
                  title="Submit a support ticket"
                  description="Share your questions or concerns with our team. We'll get back to you the soonest."
                  actions={
                    <Button
                      prefixIcon={<Icons.WixFormsFilledSmall />}
                      id="site-template-support-ticket"
                      size="small"
                    >
                      Submit Ticket
                    </Button>
                  }
                />
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  badge={
                    <Badge size="small" skin="premium">
                      Extended Support
                    </Badge>
                  }
                  size="medium"
                  title="Schedule a paid expert session"
                  description="Get assisted with configurating the template, updating site theme and more."
                  actions={
                    <Button
                      prefixIcon={<Icons.WixBookingSmall />}
                      skin="premium"
                      href="https://calendly.com/certifiedcode/site-template-support"
                      target="_blank"
                      size="small"
                      as="a"
                    >
                      Book Now
                    </Button>
                  }
                />
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  badge={
                    <Badge size="small" skin="premium">
                      Extended Support
                    </Badge>
                  }
                  size="medium"
                  title="Need help replacing site theme, images and text?"
                  description="We can help maintain your site, update images, text and more. Select a subscription plan to get started."
                  actions={
                    <Button
                      prefixIcon={<Icons.PremiumFilled />}
                      skin="premium"
                      href="https://www.certifiedcode.us/pricing/support"
                      target="_blank"
                      size="small"
                      as="a"
                    >
                      View Pricing
                    </Button>
                  }
                />
              </Card>
            </Cell>

            <Cell span={12}>{/* <BC.Offers appId="SITE_TEMPLATE" /> */}</Cell>
            <Cell span={12}>
              <EmptyState
                theme="page"
                title="More content coming soon"
                subtitle="In the meantime, you can check out our other templates and apps."
              >
                <Box gap="SP2" direction="horizontal">
                  <TextButton
                    as="a"
                    href="https://www.wix.com/app-market/developer/certified-code"
                    target={"_blank"}
                    prefixIcon={<Icons.Design />}
                  >
                    View Template Collections
                  </TextButton>
                  <TextButton
                    as="a"
                    href="https://www.wix.com/app-market/developer/certified-code"
                    target={"_blank"}
                    prefixIcon={<Icons.AppMarket />}
                  >
                    View App Collections
                  </TextButton>
                </Box>
              </EmptyState>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
